import React,{ useState,forwardRef } from 'react'
import compose from "recompose/compose";
import {inject,observer} from 'mobx-react'

import {hBox} from "gx-web-ui/utils/flex";
import { CaretRightOutlined } from '@ant-design/icons';

import TeamLinkLogo from '../assets/icons/TEAMLINK-logo.png'
import _ from 'lodash'

import CustomizedInput from './CustomizedInput'
import CustomTextPairDisplay from '../components/common/CustomTextPairDisplay'


import VisibleButton from '../components/common/VisibleButton'
import Checkbox from '@material-ui/core/Checkbox';

import FormControlLabel from '@material-ui/core/FormControlLabel';

import Link from '@material-ui/core/Link';

import Typography from '@material-ui/core/Typography';

import Button from '@material-ui/core/Button';

import GoogleLogo from '../assets/icons/GoogleLogo.svg'
import FacebookLogo from '../assets/icons/facebook.png'

import LoginForm from './loginRelated/LoginForm'
import RegisterForm from './loginRelated/RegisterForm'
import ForgetForm from './loginRelated/ForgetForm'
import { Divider, Modal } from "antd";

import {isValidUsername, isValidPassword} from '../utils/typeCheck'
import BackButton from '../components/common/BackButton'
import { reaction } from 'mobx';

import { ReactComponent as Verified } from '@src/assets/svgIcons/Verified.svg'
import { FacebookProvider, Login } from 'react-facebook'
import { GoogleLogin } from 'react-google-login'
import { message } from 'antd';


const LoginModal = (props) => {
    const { visible, handleOk=false, handleCancel,appStore,uiStore} = props

    const {login, register, setRegisteredData, loginByThirdParty, registerAndBindThirdParty} = appStore

    const {setIsVerificationOpen,setOpenLogin} = uiStore

    const [isLoginMode,setIsLoginMode] = useState(true)
    const [isKeepMeSignIn, setIsKeepMeSignIn] = useState(false)
    const [isFindingAccount, setIsFindingAccount] = useState(false)
    const [isCodeSent, setIsCodeSent] = useState(false)

    const [username, setUsername] = useState({value : '', errorMessage : null})
    const [password, setPassword] = useState({value : '', errorMessage : null})


    const [registerUsername, setRegisterUsername] = useState({value : '', errorMessage : null})
    const [registerPassword, setRegisterPassword] = useState({value : '', errorMessage : null})
    const [registerConfirmPassword, setRegisterConfirmPassword] = useState({value : '', errorMessage : null})
    const [firstName, setFirstName] = useState({value : '', errorMessage : null})
    const [lastName, setLastName] = useState({value : '', errorMessage : null})
    const [thirdPartyInfo, setThirdPartyInfo] = useState(null)
    const [waitingForBinding, setWaitingForBinding] = useState(false)
    const [waitingForActive, setWaitingForActive] = useState(false)
    const [invitationInfo, setInvitationInfo] = useState(null)
    /*invitationInfo = {
        inviterId: '111',
        role: 'client'
    }*/


    const cleanForm = () =>{

        setUsername({value : '', errorMessage : null})
        setPassword({value : '', errorMessage : null})
        setRegisterUsername({value : '', errorMessage : null})
        setRegisterPassword({value : '', errorMessage : null})
        setRegisterConfirmPassword({value : '', errorMessage : null})
        setFirstName({value : '', errorMessage : null})
        setLastName({value : '', errorMessage : null})

        setWaitingForBinding(false)
    }
    const onKeepSignIn =(e)=>{
        setIsKeepMeSignIn(!isKeepMeSignIn)
    }


    const loginCheck = () =>{

        const isValidUsernameFlag = isValidUsername(username.value || '')

        !isValidUsernameFlag && setUsername( {'value' : username.value,'errorMessage' : 'Invalid username!'})

        !password.value && setPassword( {'value' : password.value,'errorMessage' : 'Password cannot be empty!'})

        if( isValidUsernameFlag && password.value ){
            return true
        }

        return false
    }
    const onLoginClick_ = async() =>{
        console.log(username, password)

        const isValid = loginCheck()

        if(isValid){
            try{
                // await login('client@teamlink.com.au','test');
                await login(username.value,password.value, thirdPartyInfo, invitationInfo);

                cleanForm()
                setIsLoginMode(true)
                handleCancel()
            }catch(error){
                setUsername({value : username.value, errorMessage : 'Unauthorized'})
                setPassword({value : password.value, errorMessage : 'Unauthorized'})
            }
        }
    }

    const registerCheck = () =>{
        const isValidUsernameFlag = isValidUsername(registerUsername.value || '')
        const isValidRegisterPassword = isValidPassword(registerPassword.value || '')
        const isPasswordMatch = _.isEqual(registerPassword.value, registerConfirmPassword.value)
        const isFirstName = Boolean(firstName.value)
        const isLastName = Boolean(lastName.value)

        console.log(isFirstName)

        !isValidUsernameFlag && setRegisterUsername({value : registerUsername.value, errorMessage : 'Invalid username!'})
        !isValidRegisterPassword ? setRegisterPassword({value : registerPassword.value, errorMessage : 'Invalid password!'}) : !isPasswordMatch && setRegisterConfirmPassword({value : registerPassword.value, errorMessage : 'Confirm Password does not match password!'})
        !isFirstName && setFirstName({value : firstName.value, errorMessage:'Please fill your first name'})
        !isLastName && setLastName({value : lastName.value, errorMessage:'Please fill your last name'})
        if(isValidUsernameFlag && isValidRegisterPassword && isPasswordMatch && isFirstName && isLastName){
            return true
        }
        return false

    }

    const onRegisterClick_ = async() =>{

        const isValid = registerCheck()

        console.log('isValid',isValid, 'waiting for binding', waitingForBinding)

        if(isValid){
            try{
                let result

                if (waitingForBinding) {
                    const userResInfo = {
                        username : registerUsername.value,
                        password : registerConfirmPassword.value,
                        firstName : firstName.value,
                        lastName : lastName.value
                    }

                    result = await registerAndBindThirdParty({thirdPartyInfo,  userResInfo, invitationInfo})

                } else {
                    try {
                        result = await register({
                            username : registerUsername.value,
                            password : registerConfirmPassword.value,
                            firstName : firstName.value,
                            lastName : lastName.value,
                            invitationInfo
                        })
                    } catch(error) {
                        message.error(error.response?.body?.error || "Register failed")
                    }
                }
                console.log('result',result)

                setRegisteredData({id : result.id, mobile : result.mobile})

                setIsLoginMode(true)
                handleCancel()
                cleanForm()

                setIsVerificationOpen(true)


            }catch(error){

                console.log('error', JSON.stringify(error, null, 2))

            }
        }

    }

    const handleUsernameInput = (value) =>{
        setUsername({'value' : value, errorMessage : null})
    }

    const handlePasswordInput = (value) =>{
        setPassword({'value' : value, errorMessage : null})
    }

    const handleRegisterUsernameInput = value=>{
        setRegisterUsername({'value' : value, errorMessage : null})
    }

    const handleRegisterPasswordInput = value =>{
        setRegisterPassword({'value' : value, errorMessage : null})
    }

    const handleRegisterConfirmPasswordInput = value =>{
        setRegisterConfirmPassword({'value' : value, errorMessage : null})
    }

    const handleFirstNameInput = value =>{
        setFirstName({'value' : value, errorMessage : null})
    }

    const handleLastNameInput = value =>{
        setLastName({'value' : value, errorMessage : null})
    }

    const loginWithThirdParty = async (thirdPartyInfo) => {
        try{
            // await login('client@teamlink.com.au','test');
            const res = await loginByThirdParty(thirdPartyInfo)
            //console.log('login result:', res)

            if (res === 'waitingForBind') {
                setWaitingForBinding(true)
            } else if (res === 'waitingForActive') {
                setWaitingForActive(true)
            } else {
                cleanForm()
                setIsLoginMode(true)
                handleCancel()
            }

        } catch(error) {
            console.log(error)
        }
    }

    const loginWithGoogle = (res) => {
        const profile = res.profileObj

        if (profile) {
            const thirdPartyInfo = {
                socialNetworkType: 'google',
                id: profile.googleId,
                name: profile.name,
                avatar: profile.imageUrl
            }

            setThirdPartyInfo(thirdPartyInfo)
            loginWithThirdParty(thirdPartyInfo)
        }
    }

    const handleKeyPress = event => {
        if (event.key === 'Enter') {
            if(isLoginMode){
                onLoginClick_()
            }else{
                onRegisterClick_()
            }
            event.preventDefault();
        }
    };

    return <Modal
                style={{padding : 0}}
                width={500}
                visible={visible}
                footer={null}
                // onOk={(e)=>{
                //     e.stopPropagation()
                //     console.log('ok')
                //     }
                // }
                // // okButtonProps={{disabled:!isConfirmable()}}
                onCancel={()=>{
                    handleCancel()
                    cleanForm()
                    setIsFindingAccount(false)
                    setIsCodeSent(false)
                    setIsLoginMode(true)
                }}
                // okText={'Confirm'}
            >
                {
                    isFindingAccount && <div style={{position : 'absolute', top : 0, left : 0}}>< BackButton backFunction = {()=>setIsFindingAccount(false)}/></div>
                }

                <div style={{...hBox('center','center'),marginTop:16}}>
                    <img src={TeamLinkLogo} style={{height : 36, width:150}}/>
                </div>
                {
                    waitingForBinding &&
                    <Typography style={{marginTop: 25}}>{`Sucessfully logged in with your ${thirdPartyInfo.socialNetworkType.substr(0, 1).toUpperCase() + thirdPartyInfo.socialNetworkType.substr(1)} account! Your account has never been used in Teamlink. Please login your Teamlink account or register a new one to link them together.`}</Typography>
                }
                {
                    isFindingAccount ? <React.Fragment>
                        <ForgetForm closeFunction={()=>setIsFindingAccount(false)} />
                    </React.Fragment>
                    :
                    <React.Fragment>
                        {
                            isLoginMode ?
                            <LoginForm
                                handlePressEnter={handleKeyPress}
                                handleUsernameInput = {handleUsernameInput}
                                handlePasswordInput = {handlePasswordInput}
                                username={username}
                                password={password}
                            />
                            :
                            <RegisterForm
                                handlePressEnter={handleKeyPress}
                                handleRegisterUsernameInput={handleRegisterUsernameInput}
                                handleRegisterPasswordInput={handleRegisterPasswordInput}
                                handleRegisterConfirmPasswordInput={handleRegisterConfirmPasswordInput}
                                handleFirstNameInput={handleFirstNameInput}
                                handleLastNameInput={handleLastNameInput}
                                registerUsername={registerUsername}
                                registerPassword={registerPassword}
                                registerConfirmPassword={registerConfirmPassword}
                                firstName={firstName}
                                lastName={lastName}
                            />
                        }

                        {!waitingForBinding && <div style={{...hBox('center','space-between'),'marginTop' : 16}}>
                            <FormControlLabel
                                control={<Checkbox style={{'color' : 'black'}} checked={isKeepMeSignIn} onChange={onKeepSignIn} name="keepMeSignIn" />}
                                label={<Typography style={{color : 'rgb(0, 0, 0 ,0.5)', fontSize : 16}}>Stay signed in</Typography>}
                            />
                            <Link style={{'color' : 'rgb(0, 0, 0 ,0.5)','textDecoration': 'underline','textDecorationColor': 'rgb(0, 0, 0 ,0.5)',fontSize : 16}} variant="body2"  onClick={()=>setIsFindingAccount(true)}>
                                Forgot password?
                            </Link>
                        </div>}
                        <div style={{'marginTop' : 16}}>
                            {
                                isLoginMode ? <Button style={{'color':'white','background':'black','width':'100%','height' : 36,'textTransform' : 'none'}} onClick={()=>onLoginClick_()}>Login</Button>
                                :
                                <Button style={{'color':'white','background':'black','width':'100%','height' : 36,'textTransform' : 'none'}} onClick={()=>onRegisterClick_()}>Register</Button>
                            }
                        </div>
                        {waitingForBinding ?
                        null
                        :
                        <>
                            <div style={{'marginTop' : 16}}>
                                <Divider style={{'color' : 'grey', 'fontSize' : 16, 'fontWeight' : 'normal' }}>Or</Divider>
                            </div>
                            <div style={{...hBox('center','space-between'),'marginTop' : 16}}>
                                <div style={{'width':"50%",'paddingRight' : 10}}>
                                     <GoogleLogin
                                        clientId='818515026709-m6pqecthpmcv8a28p74l8c1lt5nrj7o3.apps.googleusercontent.com'
                                        render={renderProps => (
                                        // <button onClick={renderProps.onClick} disabled={renderProps.disabled}>This is my custom Google button</button>
                                            <Button
                                                onClick={renderProps.onClick}
                                                startIcon={<img src={GoogleLogo} style={{'height' : 24, 'width':24}}/>}
                                                style={{'border' : '1px solid #ccc','borderRadius' : 4,'textTransform' :  'none', 'width' : "100%"}}
                                            >Google</Button>
                                        )}
                                        buttonText="Login"
                                        onSuccess={loginWithGoogle}
                                        onFailure={(err) => {
                                            console.log(err)
                                        }}
                                        cookiePolicy={'single_host_origin'}
                                    />
                                        {/* </Button><Button style={{'color':'white','background':'black','width':'100%'}} start >Google</Button> */}
                                </div>
                                <div style={{'width':"50%",'paddingLeft' : 10}}>
                                    <FacebookProvider appId="289461828745938">
                                        <Login
                                        scope="email"
                                        onCompleted={(data) => {
                                            console.log(data)
                                            //todo
                                            console.log('login success')
                                            const profile = data.profile

                                            const thirdPartyInfo = {
                                                socialNetworkType: 'facebook',
                                                id: profile?.id,
                                                name: profile?.name,
                                                avatar: profile?.picture?.data?.url
                                            }

                                            setThirdPartyInfo(thirdPartyInfo)
                                            loginWithThirdParty(thirdPartyInfo)

                                            /*const loginData = {thirdPartyInfo}
                                            console.log(JSON.stringify(loginData, null, 4))

                                            const userResInfo = {
                                                username: '1300000000',
                                                password: '123456',
                                                firstName: 'Jony',
                                                lastName: 'Ive',
                                            }

                                            const registerData = { thirdPartyInfo, userResInfo }

                                            console.log(JSON.stringify(registerData, null, 4))*/
                                        }}
                                        onError={(err) => {
                                            console.log(err)
                                        }}
                                        >
                                        {({ loading, handleClick, error, data }) => (
                                            <Button
                                                onClick={handleClick}
                                                startIcon={
                                                    // <Facebook style={{'height' : 24, 'width':24}}/>
                                                    <img src={FacebookLogo} style={{'height' : 24, 'width':24}}/>
                                                }
                                                style={{'borderRadius' : 4,'textTransform' :  'none', 'width' : "100%", 'backgroundColor' : '#395398','font' : 'white'}}
                                            ><span style={{'color' : 'white'}}>{loading ? 'Loading...' : 'Facebook'}</span>
                                            </Button>


                                        )}
                                        </Login>
                                    </FacebookProvider>
                                </div>
                            </div>
                        </>
                        }
                        <div style={{...hBox('center','center'),'marginTop' : 16}}>
                            {
                                isLoginMode ?
                                <React.Fragment>
                                    <Typography>Don't have an account? </Typography>
                                    <Link onClick={()=>setIsLoginMode(false)}  style={{'color' : '#FF5600','fontSize' : 16, 'paddingLeft' : 8}}/*to={`/sales-in-progress/pipeline/developerInformation?id=${dealId}`}*/>
                                        Register here
                                    </Link>
                                </React.Fragment>
                                :
                                <React.Fragment>
                                    <Typography>Already have an account? </Typography>
                                    <Link onClick={()=>setIsLoginMode(true)}  style={{'color' : '#FF5600','fontSize' : 16, 'paddingLeft' : 8}}/*to={`/sales-in-progress/pipeline/developerInformation?id=${dealId}`}*/>
                                        Login
                                    </Link>
                                </React.Fragment>
                            }
                        </div>

                    </React.Fragment>
                }


            </Modal>
}
export default compose(inject('appStore','uiStore'),observer)(LoginModal)
