import React,{ useState,forwardRef } from 'react'
import compose from "recompose/compose";
import {inject,observer} from 'mobx-react'

import {hBox} from "gx-web-ui/utils/flex";

import _ from 'lodash'

import CustomizedInput from '../CustomizedInput'


import VisibleButton from '../common/VisibleButton'


import User from '../../assets/icons/user.svg'
import Lock from '../../assets/icons/Lock.svg'


const LoginForm = (props) => {
    const { handleUsernameInput, handlePasswordInput, username, password, handlePressEnter} = props

    const [isShowPassword, setIsShowPassword] = useState(false)

    return <React.Fragment>
        <div style={{...hBox('center','center'),marginTop:16}}>
            <CustomizedInput handlePressEnter={handlePressEnter} placeholderText='Email or mobile number' value={username.value}  errorMessage={username.errorMessage}  onInputChange={(e)=>handleUsernameInput(e.target.value)}  addOnBefore = {<img src={User} style={{opacity: 1}}/>}/>
        </div>
        <div style={{...hBox('center','center'),marginTop:16}}>
            <CustomizedInput handlePressEnter={handlePressEnter} placeholderText='Password' value={password.value} type={  isShowPassword ? 'text' : 'password'} errorMessage={password.errorMessage} onInputChange={ (e)=> handlePasswordInput(e.target.value)}  addOnBefore = {<img src={Lock} style={{opacity: 1}}/>}   
                addOnAfter={<VisibleButton showPassword={isShowPassword}   handleClickShowPassword={()=>setIsShowPassword(!isShowPassword)}/>}
            />
        </div>
    </React.Fragment>
}
export default compose(inject('appStore'),observer)(LoginForm)
