import React,{ useState,forwardRef } from 'react'
import compose from "recompose/compose";
import {inject,observer} from 'mobx-react'

import {hBox} from "gx-web-ui/utils/flex";
import { CaretRightOutlined } from '@ant-design/icons';

import TeamLinkLogo from '../assets/icons/TEAMLINK-logo.png'
import _ from 'lodash'
import { message } from 'antd';

import CustomizedInput from './CustomizedInput'

import PersonOutline from '@material-ui/icons/PersonOutline'

import { makeStyles } from '@material-ui/core/styles';

import VisibleButton from '../components/common/VisibleButton'
import Checkbox from '@material-ui/core/Checkbox';

import FormControlLabel from '@material-ui/core/FormControlLabel';

import Link from '@material-ui/core/Link';

import Typography from '@material-ui/core/Typography';

import ButtonBase from '@material-ui/core/ButtonBase';

import { ReactComponent as Verified } from '@src/assets/svgIcons/Verified.svg'

import {
    Modal,
    Input
} from "antd";



const VerificationModal = (props) => {
    const { visible, handleOk=false, handleCancel,uiStore, appStore} = props

    const {activate} = appStore

    const {setOpenLogin, setIsVerificationOpen} = uiStore

    const [code, setCode] = useState('')




    const handleActive = async ()=>{

        console.log(code)

        try{
            await activate(code)
            message.success('Activate successfully')
            handleCancel(false)

        }catch(error){
            message.error(error.response?.body?.error || "Activate failed")
        }
    }

    const onVerificationLogin = () =>{

        setIsVerificationOpen(false)

        setOpenLogin(true)
        
        // await login('client@teamlink.com.au','test');

    }

    const isValid =()=>{
        return code.length === 6 ? true : false
    }


    return <Modal
                width={500}
                visible={visible}
                footer={null}
                // onOk={(e)=>{
                //     e.stopPropagation()
                //     console.log('ok')
                //     }
                // }
                // // okButtonProps={{disabled:!isConfirmable()}}
                onCancel={()=>{
                    handleCancel(false)
                    console.log('cancel')
                }}
                // okText={'Confirm'}
            >
                <div style={{...hBox('center','center'),marginTop:16}}>
                    <img src={TeamLinkLogo} style={{height : 36, width:150}}/>
                </div>
                

                <div style={{ marginTop:16}}>
                    <Typography variant='body2' style={{color : 'rgb(0,0,0,0.3)'}}>
                        Please enter the verification code sent to your mobile phone.
                    </Typography>
                </div>

                <div style={{...hBox('center','center'),marginTop:16}}>
                    <Input addonBefore={<Verified style={{height : 14,width : 14}} />} placeholder='Verification code' value={code} onChange={(e)=>setCode(e.target.value)}/>
                </div>
                <div style={{marginTop:16}}>
                    <ButtonBase onClick={handleActive} disabled={!isValid()} style={{ width : "100%",'background' : isValid() ? 'black' : 'rgb(0,0,0,0.5)',borderRadius : 4, height:42}}>
                        <span style={{'color' : 'white'}}>Activate</span>
                    </ButtonBase>
                </div>
                {/* <div style={{...hBox('center','center'),marginTop:16}}>
                    <Typography>Already have an account? </Typography>
                    <Link onClick={onVerificationLogin} style={{'color' : 'orange','fontSize' : 16, 'paddingLeft' : 8}}/>
                        Login
                    </Link>
                </div> */}

            </Modal>
}
export default compose(inject('uiStore','appStore'),observer)(VerificationModal)
