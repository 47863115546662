import React,{ useState,forwardRef } from 'react'
import compose from "recompose/compose";
import {inject,observer} from 'mobx-react'

import {hBox} from "gx-web-ui/utils/flex";

import _ from 'lodash'

import CustomizedInput from '../CustomizedInput'

import { makeStyles } from '@material-ui/core/styles';

import VisibleButton from '../common/VisibleButton'

import User from '../../assets/icons/user.svg'
import Lock from '../../assets/icons/Lock.svg'
import Mobile from '../../assets/icons/Mobile.svg'



const RegisterForm = (props) => {
    const {handleRegisterUsernameInput, handlePressEnter, handleRegisterPasswordInput, handleRegisterConfirmPasswordInput, handleFirstNameInput, handleLastNameInput,registerUsername,registerPassword,registerConfirmPassword,firstName,lastName} = props

    const [isShowPassword, setIsShowPassword] = useState(false)
    const [isShowConfirmPassword, setIsShowConfirmPassword] = useState(false)


    return <React.Fragment>
        <div style={{...hBox('center','center'),marginTop:16}}>
            <CustomizedInput  handlePressEnter={handlePressEnter} errorMessage={registerUsername.errorMessage} value={registerUsername.value}  placeholderText='Mobile number' onInputChange={(e)=>handleRegisterUsernameInput(e.target.value)}  addOnBefore = {<img src={Mobile} style={{opacity: 0.5}}/>}/>
        </div>
        <div style={{...hBox('center','center'),marginTop:16}}>
            <CustomizedInput  handlePressEnter={handlePressEnter} placeholderText='Password' value={registerPassword.value} errorMessage={registerPassword.errorMessage}  type={  isShowPassword ? 'text' : 'password'} onInputChange={(e)=>handleRegisterPasswordInput(e.target.value)}   addOnBefore = {<img src={Lock} style={{opacity: 0.5}}/>}   
                addOnAfter={<VisibleButton showPassword={isShowPassword}   handleClickShowPassword={()=>setIsShowPassword(!isShowPassword)}/>}
            />
        </div>
        <div style={{...hBox('center','center'),marginTop:16}}>
            <CustomizedInput  handlePressEnter={handlePressEnter} placeholderText='Confirm password' value={registerConfirmPassword.value}  errorMessage={registerConfirmPassword.errorMessage}  type={  isShowConfirmPassword ? 'text' : 'password'} onInputChange={(e)=>handleRegisterConfirmPasswordInput(e.target.value)}   addOnBefore = {<img src={Lock} style={{opacity: 0.5}}/>}   
                addOnAfter={<VisibleButton showPassword={isShowConfirmPassword}   handleClickShowPassword={()=>setIsShowConfirmPassword(!isShowConfirmPassword)}/>}
            />
        </div>
        <div style={{...hBox('flex-start','center'),marginTop:16}}>
            <CustomizedInput  handlePressEnter={handlePressEnter} placeholderText='Firstname' value={firstName.value} errorMessage={firstName.errorMessage} onInputChange={(e)=>handleFirstNameInput(e.target.value)}  addOnBefore = {<img src={User} style={{opacity: 0.5}}/>}   />
            <div style={{marginLeft:16,marginRight:16}}></div>
            <CustomizedInput  handlePressEnter={handlePressEnter} placeholderText='Lastname'  value={lastName.value} errorMessage={lastName.errorMessage} onInputChange={(e)=>handleLastNameInput(e.target.value)}   addOnBefore = {<img src={User} style={{opacity: 0.5}}/>}   />
        </div>
    </React.Fragment>
}
export default compose(inject('appStore'),observer)(RegisterForm)
